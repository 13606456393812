@import 'styles/_mixins.scss';

.full-height {
  height: 100vh;
}

.essay-page {
  padding: 40px 0;

  @media (min-width: 768px) {
    padding: 60px 0;
  } 

  .missing-post h4 {
    font-size: 20px;
  }

  .loading::after {
    display: inline-block;
    animation: dots steps(1, end) 1s infinite;
    content: '';
  }
  
  @keyframes dots {
      0%   { content: ''; }
      25%  { content: '.'; }
      50%  { content: '..'; }
      75%  { content: '...'; }
      100% { content: ''; }
  }

  .top-link {
    margin: 0 0 40px 20px;
  }

  .bottom-link {
    margin: 40px 0 0 20px;
  }

  .top-link,
  .bottom-link {
    @media (min-width: 768px) {
      margin-left: 50px;
    } 
  }
}