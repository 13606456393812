@import 'styles/_variables.scss';

.subscribe-form {
  flex-direction: column;

  input {
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 4px;
  }

  button {
    background: white;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px 24px;

    &:hover {
      transition: ease .2s;
    }

    @media (min-width: 530px) and (max-width: 768px) {
      width: initial;
    }
  }
}

// THEME SPECIFIC STYLES 

.light .subscribe-form {
  button {
    border: 2px solid $highlight;

    @media (min-width: 768px) {
      &:hover {
        background: $highlightFadedLighter;
      }
    }
  }
}

.dark .subscribe-form {
  button {
    border: 2px solid $background;

    @media (min-width: 768px) {
      &:hover {
        background: rgba($secondary, .2);
      }
    }
  }
}
