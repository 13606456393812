@import 'styles/_variables.scss';

.home-page {
  @media (min-width: 768px) {
    display: flex;
  }

  .left {
    margin: 30px;
  }

  .left {
    @media (min-width: 768px) {
      flex: 1;
    }
  }

  .right {
    @media (min-width: 768px) {
      flex: 3;
      overflow: scroll;
      height: 100vh;
    }
  }

  .essays, 
  .projects {
    h3 {
      font-size: 20px;
    }

    h3, h4 {
      margin: 0 0 10px 0;
      text-transform: uppercase;
    }

    .time, 
    h4,
    .tags,
    .timeline {
      font-size: 14px;
    }
  }
}


