@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.margin {
  margin: 30px;
}

.project {
  border: 1px solid $secondary;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  .password {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  img,
  video {
    display: block;
    width: 100%;
  }

  .heading {
    padding: 12px;

    h3 {
      margin: 0 0 6px 0;
      font-size: 20px;

      .link-title {
        @include links;
      }
    }

    .timeline {
      margin: 0;
    }
  }

  .heading .timeline,
  .links {
    font-size: 14px;
  }

  .heading.visual {
    border-top: 1px solid $secondary;
  }

  .heading.no-visual {
    border-bottom: 1px solid $secondary;
  }

  .pitch {
    margin: 0;
    padding: 12px;
  }

  .links {
    overflow: scroll;
    border-top: 1px solid $secondary;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    background: rgba(197, 200, 203, .2);

    .link:not(:last-child) {
      border-right: 1px solid $secondary;
    }

    .link {
      padding: 6px;
      flex: 1;
      text-align: center;
    }
  }
}

.blur {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}

.blur img,
.blur video,
.blur .heading,
.blur .pitch,
.blur .links {
  filter: blur(0.5rem);
}
