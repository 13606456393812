@import 'styles/_variables.scss';

@mixin links {
  text-decoration: none;

  &:hover {
    opacity: .7;
    transition: ease .3s;
  }
}
