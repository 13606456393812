@import 'styles/_variables';

body {
  font-family: $mainFontFamily;
  margin: 0;
}

.bold {
  font-weight: bold;
}

form {
  display: flex;

  input {
    font-size: 15px;
    border: 1px solid $secondary;

    &:-internal-autofill-selected {
      background-color: white;
    }
  }
}

// THEME SPECIFIC STYLES
.light {
  color: $primary;
  background: $background;

  .link,
  form button {
    color: $highlight;
  }

  .MuiFormLabel-root.Mui-focused,
  .filters-container {
    background: $background;
  }

  .filters-container {
    &.shadow {
      box-shadow: 0px 0px 12px 3px rgba(186, 186, 186, 0.7);
    }
  }
}

.dark {
  color: $background;
  background: $primary;

  .link {
    color: $highlightFaded;
  }

  .MuiFormLabel-root.Mui-focused,
  .filters-container,
  form button {
    background: $primary;
  }

  .filters-container {
    &.shadow {
      box-shadow: 0px 0px 12px 3px rgba(29, 29, 29, 0.7);
    }
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused,
  .MuiSelect-icon,
  .MuiChip-root,
  form button {
    color: $background;
  }

  .MuiButtonBase-root {
    background: #808080;
  }

  .essay-container .content .images {
    background: $secondary;
    padding: 20px;
    border-radius: 4px;
  }
}