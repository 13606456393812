@import 'styles/_variables.scss';

.opening-text {
  h1,
  code {
    font-weight: 300;
  }

  h1 {
    margin: 5px 0 20px 0;
    font-weight: 600;
    font-size: 30px;
  }

  .emoji {
    font-size: 50px;
  }

  .bio {
    line-height: 24px;
  }
}