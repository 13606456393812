@import 'styles/_variables.scss';

.button {
  border-radius: 4px;
  border: 0;
  font-family: $mainFontFamily;
  
  &.primary {
    border: 2px solid;
    padding: 10px;
  }

  @media (min-width: 500px) and (max-width: 768px) {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    &:hover {
      transition: ease .3s;
      cursor: pointer;
    }
  }

  @media (max-width: 500px) {
    &:focus {
      outline-style: dotted;
      outline-width: medium;
      outline-offset: 5px;
    }
  }
}

.light .button {
  color: $highlight;
  background: white;

  &.primary {
    border-color: $highlight;
  }

  @media (min-width: 768px) {
    &:hover {
      background: $highlightFadedLighter;
    }
  }

  @media (max-width: 500px) {
    &:focus {
      outline-color: $highlight;
    }
  }
}

.dark .button {
  color: white;
  background: $primary;

  &.primary {
    border-color: white;
  }

  @media (min-width: 768px) {
    &:hover {
      background: #666667;
    }
  }

  @media (max-width: 500px) {
    &:focus {
      outline-color: $primary;
    }
  }
}




