@import 'styles/_variables.scss';

.password-modal {
  .error, 
  .success,
  .unlocked-links,
  .form {
    padding: 0 20px;
  }
}

.password-modal {
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid $secondary;
  border-radius: 4px;
  padding: 15px 30px 15px 30px;

  .overflow {
    overflow: scroll;
    max-height: 70vh;
    max-width: 70vw;
    min-width: 50vw;
  }

  p {
    margin-bottom: 0 15px;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    background: transparent;
  }

  .form {
    justify-content: space-between;
    padding: 0;

    input {
      border-radius: 4px 0 0 4px;
      flex: 2;
      border-right: 0;
      padding: 10px;
    }

    .submit {
      flex: 1;
      border-radius: 0 4px 4px 0;
    }

    &.shaking {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
    }
    
    @keyframes shake {
      10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
    
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%, 60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

  .error, 
  .success {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .error {
    margin-bottom: 0;
  }

  .success {
    margin-bottom: 10px;
  }

  .unlocked-links {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }

  .exit {
    font-size: inherit;
  }

  .project {
    margin-bottom: 15px;
  }
}

.light .password-modal {
  background: white;
  box-shadow: 0px 0px 20px 0px rgba(186, 186, 186, .6);

  .form {
    .submit {
      border: 2px solid $highlight;
    }
  }
}

.dark .password-modal {
  background: $primary;
  box-shadow: 0px 0px 12px 3px rgba(29, 29, 29, 0.7);

  .form {
    .submit {
      border: 2px solid white;
    }
  }

  .exit {
    color: $highlightFaded;
  }
}
