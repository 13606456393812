@import 'styles/_variables.scss';

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  a {
    border-bottom: 0;
  }

  img {
    width: 34px;
  }
}
