@import 'styles/_variables.scss';

.filters-container {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 30px 30px 0 30px;

  &.shadow {
    transition: ease .3s;
  }

  .title {
    margin: 0;
    font-size: 20px;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 20px 0;
  
    @media (min-width: 600px) {
      align-items: center;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;
    }

    fieldset {
      border-color: $secondary;
    }

    .MuiFormLabel-root {
      font-weight: bold;
    }

    .MuiFormLabel-root,
    .MuiChip-root {
      font-family: $mainFontFamily;
    }

    .MuiFormLabel-root.Mui-focused {
      padding: 0 6px;
    }
  }
}

.MuiPaper-root {
  max-height: calc(100% - 320px) !important;
}