@import 'styles/_variables.scss';

.essay-container {
  margin: 20px;

  @media (min-width: 768px) {
    max-width: 700px;
    margin: 0 auto;
  } 

  .details {
    line-height: 6px;
    padding-bottom: 26px;
    margin-bottom: 26px;
    border-bottom: 1px solid $secondary;
  }

  .content {
    .images {
      max-width: 100%;

      @media (min-width: 768px) {
        max-width: 500px;
        display: block;
        margin: 0 auto;
      }    
    }
  }

  .title {
    font-size: 40px;
  }

  .content {
    line-height: 26px;
  }

  .thesis {
    font-size: 18px;
    font-weight: bold;
  }

  .quote {
    border-left: 3px solid;
    margin-left: 20px;
    padding-left: 20px;
    font-style: italic;
  }

  h2 {
    margin-top: 40px;
  }
}